<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
              <v-spacer></v-spacer>
              <template>
                <download-excel :data="orgseriesdetails" :fields="headers_series1" worksheet="Series report"
                  name="SeriesWiseReport.xls">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                    </template>
                    <span>Export to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>
         
            <v-data-table :headers="headers_series" :items="orgseriesdetails" :search="search">

            </v-data-table>
           
          </v-card>
        </div>
        </template>
      </div>
    </section>
    <!-- /. section -->
    <!-- details -->
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    circular: false,
    orgBookslist: [],
    orgbookdetails: [],
    orgdeptdetails: [],
    orgseriesdetails: [],
    loading: true,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    search_book: "",
    org_name: null,
    dialog_details: false,
    init_loading: false,
    dialog_department: false,
    dialog_series: false,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Reports',
        disabled: false,
        href: '/reports',
      },
      {
        text: 'Series Wise Report',
        disabled: true,
        href: '',
      },
    ],
    maxauthor: 0,
    headers_series: [
      { text: "Sr. No.", value: "srno" },
      { text: "Series", value: "seriesname" },
      { text: "Book Count", value: "Book_count" },
      { text: "Book Issued", value: "bookIssueditem" },
      { text: "Book Reserved", value: "bookReserveditem" },
      { text: "Book Available", value: "bookAvlitem" },
    ],
    headers_series1: {
      "Sr. No.": "srno",
      "Series": "seriesname",
      "Book Count": "Book_count",
      "Book Issued": "bookIssueditem",
      "Book Reserved": "bookReserveditem",
      "Book Available": "bookAvlitem",
    },


    single_org: {
      Sr_No: "srno",
      Orgnization: "orgName",
      Accession_Number: "accession_number",
      Barcode: "barcode",
      ISBN: "isbn",
      Classification_NO: "cno",
      Title: "title",
      Author1: "author1",
      Author2: "author2",
      Author3: "author3",
      Author4: "author4",
      Author5: "author5",
      Edition: "edition",
      Series: "Series",
      Dept: "dept",
      Pages: "pages",
      Book_Format: "bookformat",
      Book_Type: "booktype",
      Book_Category: "bookcategory",
      Publisher: "publisher",
      Publish_year: "publisher_year",
      Localtion: "location",
      Bill_NO: "billno",
      Alcual_Price: "actualprice",
      Discount: "discount",
      Price: "price",

    },
    all_org: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      "Number of Books": "totslBooks",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.init_loading = true;
      axios
        .post("/Librarian/getSeriesreport")
        .then((res) => {
          if (res.data.msg == "200") {
            this.loading = false;
            this.orgseriesdetails = res.data.orgseriesdetails;
            this.org_name = res.data.orgName;
            this.dialog_series = true;
            this.init_loading = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },


    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1024px) {}
</style>
